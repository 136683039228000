<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form-wizard color="#5664d2" ref="formWizard" :on-validate="validateForm()">
      <tab-content :title="$t('Projects.AddProject.Step1.Name')" icon="mdi  mdi-widgets-outline" >
            <div class="row">
              <div class="col-6">
                <div class="card">
                  <div class="card-body">
                      <div class="form-group">
                        <label for="name">{{ $t("Projects.AddProject.Form.Name") }}</label>
                        <input
                            id="name"
                            v-model="project.name"
                            type="text"
                            class="form-control"
                            :placeholder="$t('Projects.AddProject.Form.PlaceholderName')"
                            :class="{ 'is-invalid': submitted && $v.project.name.$error }"
                        />
                        <div
                            v-if="submitted && !$v.project.name.required"
                            class="invalid-feedback"
                        >
                          {{ $t("Projects.AddProject.Form.NameError") }}
                        </div>
                        <div
                            v-if="submitted && !$v.project.name.maxLength"
                            class="invalid-feedback"
                        >
                          {{ $t('General.Form.Validate.Maxlength', {count: 250}) }}
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="url">{{ $t("Projects.AddProject.Form.Url") }}</label>
                        <input
                            id="url"
                            v-model="project.url"
                            type="url"
                            class="form-control"
                            :placeholder="$t('Projects.AddProject.Form.PlaceholderUrl')"
                            :class="{ 'is-invalid': submitted && $v.project.url.$error }"
                        />
                        <div
                            v-if="submitted && !$v.project.url.required"
                            class="invalid-feedback"
                        >
                          {{ $t("Projects.AddProject.Form.UrlError") }}
                        </div>
                        <div
                            v-if="submitted && !$v.project.url.url"
                            class="invalid-feedback"
                        >
                          {{ $t("Projects.AddProject.Form.UrlErrorUrl") }}
                        </div>
                        <div
                            v-if="submitted && !$v.project.url.maxLength"
                            class="invalid-feedback"
                        >
                          {{ $t('General.Form.Validate.Maxlength', {count: 250 }) }}
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <b-card header-class="bg-transparent border-info" class="border border-info">
                  <template v-slot:header>
                    <h5 class="my-0 text-info">
                      <i class="mdi mdi-alert-circle-outline mr-3"></i>
                      {{$t('Projects.AddProject.Info.Step1.WhatNext.Title')}}
                    </h5>
                  </template>
                  <p class="card-text">
                    {{$t('Projects.AddProject.Info.Step1.WhatNext.Text')}}
                  </p>
                </b-card>
              </div>
            </div>
      </tab-content>
      <tab-content :title="$t('Projects.AddProject.Step2.Name')" icon="mdi  mdi-widgets-outline" >
        <CodeProject :auth_key = "auth_key" />
      </tab-content>

      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left">
          <b-button
              v-if="props.activeTabIndex > 0"
              class="wizard-btn"
              @click="props.prevTab()"
              :style="props.fillButtonStyle">
            {{$t('General.Base.Back')}}
          </b-button>
        </div>
        <div class="wizard-footer-right">
          <b-button
              v-if="!props.isLastStep"
              @click="createProjectNextStep(props)"
              class="wizard-footer-right wizard-btn"
              :style="props.fillButtonStyle">
            {{$t('General.Base.Next')}}
          </b-button>

          <b-button
              v-else
              @click="complete()"
              class="wizard-footer-right finish-button wizard-btn"
              :style="props.fillButtonStyle">
            {{props.isLastStep ? $t('General.Base.Save') : $t('General.Base.Next')}}
          </b-button>
        </div>
      </template>

    </form-wizard>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {FormWizard, TabContent} from "vue-form-wizard";
import {required, url, maxLength} from "vuelidate/lib/validators";
import {projectsService} from "@/helpers/fakebackend/projects.service";
import router from "@/router";
import CodeProject from './_code'

export default {
  name: "AddProject",
  components: { Layout, PageHeader, FormWizard, TabContent, CodeProject },
  page: {
    title: "Create Project",
    meta: [{ name: "Create Project" }],
  },
  data: function () {
    return {
      title: this.$t("Projects.AddProject.AddProject"),
      items: [
        {
          text: this.$t("General.Breadcrumbs.Projects"),
          href: "/projects",
        },
        {
          text: this.$t("General.Breadcrumbs.CreateProject"),
          active: true,
        },
      ],
      projects:[],
      project: {
        name: "",
        url: ""
        // status: 0
      },
      auth_key: "",
      submitted: false
    }
  },
  validations: {
    project: {
      name: { required, maxLength: maxLength(250) },
      url: { required, url, maxLength: maxLength(250) }, //@TODO: validation url
      // status: { required }
    }
  },
  methods: {
    ...projectsService,
    validateForm() {
      return false
    },
    createProjectNextStep(props) {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.createProject(this.project).then((data) => {
          this.$store.dispatch('projects/init')
          this.auth_key = data.auth_key
        }) //@TODO: добавить обработку ошибок
      }
      this.submitted = false;

      props.nextTab()
    },
    handleSubmit() {


      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {

        this.createProject(this.project).then(project => {
          this.projects.push(project)
        }) //@TODO: добавить обработку ошибок

        this.showmodal = false;
      }
      this.submitted = false;
      this.customers = {};
    },
    /**
     * hode mondal on close
     */
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showmodal = false;
      this.contacts = {};
    },
    complete() {
      //@TODO: Если создан первый проект перекинуть на следующий щаг, создание тега
      router.push('/projects')
    },
    loadProject() {
      this.getProject(this.$route.params.id).then(result => {
        this.project = result
      })
    }
  },
  mounted() {
    // this.$refs.formWizard.activateAll()
    if (this.$route.params.id == 0) {
      return
    }
    this.loadProject()
    this.$refs.formWizard.activateAll()
  }
}
</script>

<style scoped>

</style>
